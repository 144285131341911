<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
    >
      <b-form>
        <b-row>
          <!-- Name Building -->
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên cấp độ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên cấp độ"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="dataForm.name"
                  name="name"
                  placeholder="Tên cấp độ"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="dataForm.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Thứ tự"
              label-for="orderNo"
            >
              <b-form-input
                id="orderNo"
                v-model="dataForm.orderNo"
                name="orderNo"
                placeholder="Nhập thứ tự"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="float-left">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="handleSaveData"
              >
                Lưu
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Flag } from '@/const/flag'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    vSelect,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({
        id: null,
        rowNum: null,
        name: '',
        status: Flag.ACTIVE,
        orderNo: 1,
      }),
    },
    modalShow: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      isLoading: false,
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
    }
  },
  methods: {
    ...mapActions({
      saveData: 'factTopicLevels/saveData',
    }),
    // handle even
    async handleSaveData(e) {
      e.preventDefault()
      const res = await this.saveData(this.dataForm)
      if (res.isSuccessful) {
        this.showToast(res.message, 'CheckIcon', 'success')
        this.$emit('saveDataSuccess', true)
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning')
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
